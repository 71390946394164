.legend {
    position: absolute;
    z-index: 999;
    right: 0px;
    top: 65%; 
    padding:20px 10px;
    box-shadow: 0 0 5px 0 rgba(184, 140, 140, 0.3);
    font-size:12px;
    letter-spacing:2px;
    background: rgba(0,0,0,0.8);
    border-radius:5px;
    width: 20%;
  }
  .legend .title {
    color: white;
    position: relative;
    display: flex;
    height: 40px;
    width: 100%;
    padding: 0 0;
    letter-spacing:1px;
  }
  .legend > div {
    color:white;
    position: relative;
    display: flex;
    height: 20px;
    padding: 0 10px;
  }
  .legend > div::before {
    content: "";
    background-color: var(--color);
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    
  }
  @media (max-width: 768px) {
    .legend > div::before {
    width: 10px;
    height: 10px;
  
   
  }
  .legend {
    left: 0px;
    top: 55%;
    font-size:10px;
    letter-spacing:0px;
    width: 45%;
    height:40%;
  }
  
  }